import moment from "moment";
import commonService from "../../../services/common-service";

const confirmOrder = ({
  values,
  shippingPhoneValue,
  billingPhoneValue,
  cartList,
  subTotal,
  total,
  discount,
  totalDiscount,
  couponDiscount,
  shippingCharge,
  isBillingSameAsShipping,
  dispatch,
  OrderActions,
  history,
  isCustomerLoggedIn,
  customerInfo,
  currency,
  rate,
  symbol,
  isCouponApplied,
  coupon,
  additionalInfo,
}) => {
  const date = new Date();
  const newDate = moment(date).format("YYYY-MM-DD HH:mm");
  cartList.forEach((product) => {
    product.productId = product.id;
    if (product.subCategoryList && product.subCategoryList.length) {
      product.subCategoryList.forEach((subCategory) => {
        subCategory.subCategoryId = subCategory.id;
        subCategory.subCategoryName = subCategory.name;
      });
    }
    if (product.categoryList && product.categoryList.length) {
      product.categoryList.forEach((category) => {
        category.categoryId = category.id;
        category.categoryName = category.name;
        if (category.subCategoryList && category.subCategoryList.length) {
          category.subCategoryList.forEach((subCat) => {
            subCat.subCategoryId = subCat.id;
            subCat.subCategoryName = subCat.name;
          });
        }
      });
    }
    if (product.taxList && product.taxList.length) {
      product.taxList.forEach((tax) => {
        tax.taxId = tax.id;
        tax.taxName = tax.name;
      });
    }
  });

  const shippingPhone = commonService.getPhoneNumber(shippingPhoneValue.format2);

  const shippingAddress = {
    firstName: values.shipping_firstName,
    lastName: values.shipping_lastName,
    mobileNo: shippingPhone.phone,
    email: values.shipping_email,
    addressLine1: values.shipping_address,
    addressLine2: "",
    city: values.shipping_city,
    state: values.shipping_state,
    country: values.shipping_country,
    zip: values.shipping_pinCode,
    countryCode: shippingPhone.code,
  };
  let billingAddress = {};
  if (!isBillingSameAsShipping) {
    const billingPhone = commonService.getPhoneNumber(billingPhoneValue.format2);

    billingAddress.firstName = values.billing_firstName;
    billingAddress.lastName = values.billing_lastName;
    billingAddress.mobileNo = billingPhone.phone;
    billingAddress.email = values.billing_email;
    billingAddress.addressLine1 = values.billing_address;
    billingAddress.addressLine2 = "";
    billingAddress.city = values.billing_city;
    billingAddress.state = values.billing_state;
    billingAddress.country = values.billing_country;
    billingAddress.zip = values.billing_pinCode;
    billingAddress.countryCode = billingPhone.code;
  } else {
    billingAddress = { ...shippingAddress };
  }

  const orderProcessedAmount = {};
  // if (currency !== 'INR') {
  orderProcessedAmount.subTotal = commonService.getConvertedPrice(subTotal, rate, currency);
  orderProcessedAmount.productDiscount = commonService.getConvertedPrice(discount, rate, currency);
  orderProcessedAmount.couponDiscount = commonService.getConvertedPrice(couponDiscount, rate, currency);
  orderProcessedAmount.totalDiscount = commonService.getConvertedPrice(totalDiscount, rate, currency);
  orderProcessedAmount.orderTotal = commonService.getConvertedPrice(total, rate, currency);
  orderProcessedAmount.shippingCharge = shippingCharge;
  orderProcessedAmount.taxAmount = 0;
  orderProcessedAmount.orderBaseCurrency = currency;
  orderProcessedAmount.orderConversionRate = rate;
  orderProcessedAmount.orderBaseSymbol = symbol;
  // }

  const orderCustomerInfo = {
    orderCustomerEmail: shippingAddress.email,
    orderCustomerPhone: shippingAddress.mobileNo,
    orderCustomerFirstName: shippingAddress.firstName,
    orderCustomerLastName: shippingAddress.lastName,
    orderCustomerName: `${shippingAddress.firstName} ${shippingAddress.lastName}`,
    orderCustomerCountryCode: shippingAddress.countryCode,
  };

  const orderObj = {
    productList: [...cartList],
    orderAmountInfo: {
      subTotal,
      productDiscount: discount,
      couponDiscount,
      totalDiscount,
      orderTotal: total,
      shippingCharge: shippingCharge,
      taxAmount: 0,
    },

    orderProcessedAmountInfo: { ...orderProcessedAmount },
    couponCodeList: isCouponApplied ? [coupon.code] : [],
    couponApplied: isCouponApplied,
    sameAsShippingAddress: isBillingSameAsShipping,
    paymentMode: values.paymentType,
    customerId: isCustomerLoggedIn ? customerInfo.id : "",
    billingAddress: { ...billingAddress },
    shippingAddress: { ...shippingAddress },
    orderPlacedIpAddress: "",
    orderTime: new Date(newDate).toJSON(),
    zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
    orderCustomerInfo,
    additionalInfo,
  };
  dispatch(OrderActions.placeOrderAction(orderObj, history));
};

const codError = () => {
  commonService.showNotification("error", "Cannot place your order", "Exceeded cash on delivery limit");
};

const phoneError = (shippingPhoneValue, billingPhoneValue) => {
  let mess = "";
  if (!shippingPhoneValue || shippingPhoneValue.isValid) {
    mess = "Please enter a valid shipping mobile number";
  } else {
    mess = "Please enter a valid billing mobile number";
  }

  commonService.showNotification("error", "Cannot place your order", mess);
};

const validateOrder = (values, selectedSC, codLimit, total) => {
  return selectedSC !== null && selectedSC.country === "India" ? (values.paymentType === "COD" && total > codLimit ? false : true) : true;
};

const checkoutProcessService = {
  confirmOrder,
  codError,
  validateOrder,
  phoneError,
};

export default checkoutProcessService;
