import React, { Suspense, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import { Provider } from "react-redux";
import routes from "./shared/routes";
// import "antd/dist/antd.css";
import MainLayout from "./layouts/MainLayout";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import Loading from "./components/loading/loading";
import commonUtil from "./shared/common";
// const publicIp = require('public-ip');

/* (async () => {
	console.log(await publicIp.v4());
	//=> '46.5.21.123'

	
})(); */

const App = ({ store }) => {

  useEffect(()=>{
    // commonUtil.blockInspect()
    // console.log(commonUtil.getIP())
  },[])

  return (
    <Provider store={store}>
      <BreadcrumbsProvider>
        <Suspense fallback={<Loading />}>
          <BrowserRouter>
            <MainLayout headerContainerClass="container-fluid" headerPaddingClass="header-padding-2" headerTop="visible">
              {renderRoutes(routes)}
            </MainLayout>
          </BrowserRouter>
        </Suspense>
      </BreadcrumbsProvider>
    </Provider>
  );
};

export default App;
